import React from 'react';
import './Buttons.sass'

const Button = (props) => {

	const handleClick = (e) => {
		props.handleClick(e)
	}

	return (
		<button className={props.className || ''} disabled={props.disabled || false} onClick={handleClick}>{props.children}</button>
  	)
};

export default Button;
