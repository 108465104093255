import SVG from 'react-inlinesvg';
import './ButtonGroup.sass'
import check from '../../Images/check-regular.svg'

const ButtonGroup = (props) => {


	return (
		<div onClick={props.click} data-conditional={props.conditional} data-title={props.value} className={`button__group ${props.active && 'active'}`}>
			<SVG src={props.image} />
			{props.active && <SVG className="checked__icon" src={check} />}
			<p>{props.title}</p>
		</div>
  );
};

export default ButtonGroup;
