import React from 'react';
import './LoaderSpinner.sass'

const LoaderSpinner = () => {
	return <div className="teutloff__loaderspinner">
		<div className="spinner__element">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
	  </div>
  </div>;
};

export default LoaderSpinner;
