import './Step.sass'
import ButtonGroup from '../Button/ButtonGroup';
import iconAbitur from '../../Images/iconAbitur.svg'
import iconAndere from '../../Images/iconAndere.svg'
import iconElektro from '../../Images/iconElektro.svg'
import iconGewerblich from '../../Images/iconGewerblich.svg'
import iconHauptschule from '../../Images/iconHauptschule.svg'
import iconKaufmaennisch from '../../Images/iconKaufmaennisch.svg'
import iconKeine from '../../Images/iconKeine.svg'
import iconLogistik from '../../Images/iconLogistik.svg'
import iconMetall from '../../Images/iconMetall.svg'
import iconRealschule from '../../Images/iconRealschule.svg'
import iconSchichtbegleitend from '../../Images/iconSchichtbegleitend.svg'
import iconSchweissen from '../../Images/iconSchweissen.svg'
import iconTeilzeit from '../../Images/iconTeilzeit.svg'
import iconVollzeit from '../../Images/iconVollzeit.svg'
// import iconCheck from '../../Images/check-regular.svg'
// import { Slider } from '../Slider/Slider';
import SliderInput from '../Slider/RCSlider';
import Checkbox from '../Checkbox/Checkbox';

const images = {
	iconAbitur: iconAbitur,
	iconAndere: iconAndere,
	iconElektro: iconElektro,
	iconGewerblich: iconGewerblich,
	iconHauptschule: iconHauptschule,
	iconKaufmaennisch: iconKaufmaennisch,
	iconKeine: iconKeine,
	iconLogistik: iconLogistik,
	iconMetall: iconMetall,
	iconRealschule: iconRealschule,
	iconSchichtbegleitend: iconSchichtbegleitend,
	iconSchweissen: iconSchweissen,
	iconTeilzeit: iconTeilzeit,
	iconVollzeit: iconVollzeit
}


const Step = ({ data, results, setResults }) => {


	const handleCheckboxChange = (e, slug, index) => { 
		console.log(results, slug)
		const updatedCheckedState = results[slug].map((item, i) => {
			if (i === index) {
				return {
					value: item.value,
					checked: !item.checked
				}
			} else {
				return {
					value: item.value,
					checked: item.checked
				}
			}
		}
		);

		setResults({
			...results,
			[slug]: updatedCheckedState
			
		})
	}

	// const handleChange = (e) => {
	// 	console.log(e)
	// 	setResults(				
	// 		{
	// 			...results,
	// 			[data.slug]: parseInt(e.target.value)
	// 		}
	// 	)
	// } 

	const handleSliderChange = (e) => {
		setResults(
			{
				...results,
				[data.slug]: e
			}
		)

	}

	const handleClick = (e, title) => { 

		let conditional
		if (data.switch) {
			conditional = data.switch + "" === e.target.dataset.conditional			
		} else {
			conditional = results.conditional
		}

		if (e.target.dataset.title === title) {
			setResults(				
				{
					...results,
					[data.slug]: title,
					conditional: conditional
				}
			)

		}
		return
	}

	return (
	<div className={`teutloff__bildungskompass-step`}>
			<p className="react__h3">{data.title}</p>
			
			{data.type === 'button' &&
				<div className={`teutloff__question-buttons`}>
					{data.answers.map((a, index) => { 
						return (
							<ButtonGroup
								active={results[data.slug] === a.value}
								image={images[a.image]}
								title={a.title}
								value={a.value}
								key={index}
								click={(e) => handleClick(e, a.value)}
								conditional={a.conditional}

							/>
							)
					})}
				</div>
			}
			{data.type === 'slider' &&
				<><SliderInput 
					min={data.answers[0].value.min.value}
					max={data.answers[0].value.max.value}
					// onChange={handleChange}
					handleChange={handleSliderChange}
					value={results[data.slug]}
				/>
				{/* <Slider
					labelBefore={data.answers[0].labelBefore}
					labelAfter={data.answers[0].labelAfter}
					min={data.answers[0].value.min.value}
					max={data.answers[0].value.max.value}
					minLabel={data.answers[0].value.min.label}
					maxLabel={data.answers[0].value.max.label}
					handleChange={handleChange}
				/> */}
				</>
			}
			{data.type === 'checkbox' &&
				<div className={`teutloff__question-buttons-checkbox`}>
				{data.answers.map((a, index) => { 
					return (
						<Checkbox
							key={index}
							index={index}
							slug={data.slug}
							id={`checkbox_${index}`}
							value={a.value}
							title={a.title}
							checked={results[data.slug] ? results[data.slug][index].checked : false}
							change={handleCheckboxChange}
						/>
						)
				})}
				</div>				
			}
			{data.answers.map((a, index) => { 
				if (a.info) {
					return <p key={index}>{a.info}</p>
				}
				return null
			})}

	</div>	
	);
};

export default Step;