import './App.sass'
import { Provider } from './Components/Context/Context';
import { AppData } from './AppData';
const url = document.querySelector('.teutloff_bildungskompass').dataset.url;

const App = () => {

	return (
		<Provider url={url}>
			<AppData/>
		</Provider>
	);
}

export default App;
