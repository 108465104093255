import React from 'react';
import LoaderSpinner from './LoaderSpinner';

const Loader = ({results}) => {

	const createSearchUrl = () => {
		let url = '/kurse?'
		Object.entries(results).map(e => {
			if (e[0] !== 'conditional') {
				if (typeof e[1] !== 'object') {
					url += `${e[0]}=${e[1]}&`
				} else { 
					let arrayString = `${e[0]}=`
					console.log(e);
					e[1].map(v => { 
						if (v.checked) {
							return arrayString += `${v.value},`							
						}
						return v
					})
					url += arrayString.slice(0,-1) + '&'
				}
			}
			return e
		})
		return url.slice(0,-1)
	}

	setTimeout(() => { 
		// console.log(results);
		window.location.href = createSearchUrl()
	}, 50)



	return (
		<div className="teutloff__bildungskompass-step">
			<h3>Wir stellen Ihre individuelle Kursauswahl zusammen!</h3>
			<LoaderSpinner />
		</div>
	);
};

export default Loader;
