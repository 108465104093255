import React, { useState } from 'react';
import Draggable from 'react-draggable';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Slider.sass'

const SliderInput = ({ min, max, handleChange, value }) => {
	const [isDragging, setIsDragging] = useState(false);
	const [sliderValue, setSliderValue] = useState(value);

  const handleDragStart = () => {
    setIsDragging(true);
  };
	
	const onChange = (newValue) => { 
		setSliderValue(newValue)
		handleChange(newValue)
		// console.log(newValue)
	}

  const handleDragStop = () => {
    setIsDragging(false);
  };
	
	const getCurrentValueText = value => { 
		if (value === 0) {
			return 'Keine'
		} else if (value === 1) {
			return 'Mind. 1 Jahr'
		} else { 
			return 'Mind. ' + value + ' Jahre'
		}
	}

  const handleSliderChange = newValue => {
    onChange(newValue);
  };

	return (
		<>
			<p className="range__thumb-label">{getCurrentValueText(sliderValue)}</p>
    <Draggable
      axis="x"
      bounds={{ left: 0, right: 200 }}
      position={{ x: value }}
      onStart={handleDragStart}
      onStop={handleDragStop}
    >
		<div className="slider-input">
			<Slider
			min={min}
			max={max}
							value={value}
							dots={false}
						trackStyle={{
							backgroundColor: '#79E542',
							height: 12,
						}}
						railStyle={{
							backgroundColor: '#E5E5E5',
							height: 12,
						}}
						handleStyle={{
							borderColor: '#79E542',
							height: 30,
							width: 30,
							// marginLeft: -10,
							marginTop: -10,
							opacity: 1,
							backgroundColor: '#79E542',
						}}
			onChange={handleSliderChange}
			/>
			<div className={`handle ${isDragging ? 'active' : ''}`} />
		</div>
			</Draggable>
			<div className="range__labels"><label>keine</label><label>mind. 7 Jahre</label></div>
		</>
  );
};

export default SliderInput;
