import './ProgressBar.sass';


const ProgressBar = (props) => {

	const calculatedWidth = (current, total) => { 
		return 100 * current / total
	}


	return (
		<div className="teutloff__progress-bar">
			<div
				className="teutloff__progress_inner"
				style={{
					width: calculatedWidth(parseInt(props.current), parseInt(props.count)) + '%'
			}}></div>
  		</div>		
	)
	  
};

export default ProgressBar;
