import { useContext, useEffect } from 'react'
import Navigation from './Components/Navigation/Navigation'
import Step from './Components/Step/Step'
import Loader from './Components/Loader/Loader'
import { Context } from './Components/Context/Context'
import LoaderSpinner from './Components/Loader/LoaderSpinner'

export const AppData = () => {

	const { data, setData, results, setResults, loading } = useContext(Context);
	// const [results, setResults] = useState({ conditional: false })



	useEffect(() => {
		const answers = []
		let slug = ""

		data.questions.map((q) => {
			q.answers.map((a, index) => {
				if ((a.type === "checkbox")) {
					slug = q.slug
					return answers.push({
						checked: false,
						value: a.value
					})
				} if (a.type === "range") {
					setResults(r => ({
						...r,
						[q.slug]: a.value.min.value
					}))
				}
				return q
			})

			return q.answers
		})
		setResults(r => ({
			...r,
			[slug]: answers
		}));
		// eslint-disable-next-line
	}, [loading])

	return (
		<div className={`teutloff__kursfinder parallax__item`}>
			<p className="h4">Finden Sie in wenigen Schritten Ihre Weiterbildung!</p>
			{loading ? <LoaderSpinner /> :
				
				data.current < data.questions.length &&
				<Step
					results={results}
					setResults={setResults}
					data={data.questions[data.current]} />

			}
			

			{data.current > 1 && data.current === data.questions.length && <Loader data={data} results={results} />}

			{data.current < data.questions.length &&
				<Navigation
					current={data.current}
					count={data.questions.length}
					setData={setData}
					data={data}
					results={results}
				/>
			}

		</div>
	)
}
