import React from 'react';
import './Navigation.sass'
import Button from '../Button/Button';
import ProgressBar from '../ProgressBar/ProgressBar';
import Next from '../../Images/arrow-next.svg'
import Prev from '../../Images/arrow-prev.svg'

const sendStepToDataLayer = (step) => { 
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'formStep',
		step: step,
		formType: 'multiStep',
		timestamp: new Date().getTime(),
		clientId: ''
	});
	console.log(window.dataLayer)
}


const Navigation = (props) => {

	const isEnabled = () => { 

		let isEnabled = false

		const question = props.results[props.data.questions[props.data.current].slug]


		if (typeof question === 'object') {
			question.map(q => {
				if (q.checked === true) {
					return isEnabled = true
				}
				return isEnabled
			})

		} else {

			if (typeof question !== 'undefined') {
				return isEnabled = true
			}

		}

		return isEnabled
		

	}


	const prevStep = () => {
		let step = 1
		props.results.conditional === false
			&& props.data.questions[props.data.current - 1].conditional ? step = 2 : step = 1
		if (props.current > 0) {
			props.setData({
				...props.data,
				current: props.data.current - step
			})
		}
	}
	const nextStep = () => { 
		let step = 1
		let countSwitchValues = 0
		props.data.questions.map(q => q.switchValue ? countSwitchValues++ : null)
	
		props.results.conditional === false
			&& props.data.questions[props.data.current].switch
			&& props.data.questions[props.data.current + 1].conditional ? step = 2 : step = 1
		if (props.data.questions[props.data.current].conditionalValue === props.results[props.data.questions[props.data.current].slug]) {
			step = countSwitchValues + 1
		}
		if (props.current < props.count) {
			props.setData({
				...props.data,
				active: props.current === props.data.active ? props.data.active + step : props.data.active,
				current: props.data.current + step
			})				
		}
		sendStepToDataLayer(props.data.current + 1)
	}

	

	return (
		<nav className='teutloff__bildungskompass-footer'>
			<Button className="tf__secondary__button" disabled={props.current === 0} handleClick={prevStep}><img src={Prev} alt="Zurück"/>Zurück</Button>
			<ProgressBar
				current={props.current}
				count={props.count}
				/>
			<Button className="tf__main__button" disabled={!isEnabled()} handleClick={nextStep}>Weiter<img src={Next} alt="Weiter"/></Button>
		</nav>
	);
};

export default Navigation;
