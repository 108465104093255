import './Checkbox.sass'
import Checkmark from '../../Images/check-regular.svg'

const Checkbox = (props) => {

	const handleChange = e => { 
		props.change(e, props.slug, props.index)
	}

	return (
		<div className="checkbox__group">
			<input checked={props.checked} onChange={handleChange} type="checkbox" name={props.id} id={props.id} value={props.value} />
			<label htmlFor={props.id} style={{backgroundImage: `url(${Checkmark})`}}>{props.title}</label>
  </div>);
};

export default Checkbox;
