import React, { useState, createContext } from "react";
import jsonData from '../../data.json'
// import axios from 'axios'

export const Context = createContext();
export const Provider = ({ children, url }) => {

	// eslint-disable-next-line
	const [loading, setLoading] = useState(false);

	const [results, setResults] = useState({ conditional: false });
	const [data, setData] = useState(jsonData);


	return (
		<Context.Provider value={{ results, setResults, data, setData, loading }}>
			{children}
			
		</Context.Provider>
	);
}